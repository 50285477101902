.footer {
  margin-top: 140px;
  padding-top: 140px;

  @include desktop {
    padding-top: 0;
    margin-top: 0;
  }
}

/* subscription */
.subscription {
  position: absolute;
  width: 100%;

  @include desktop {
    background: $bg-gradient-secondary;
    position: static;
  }

  &.bg-white {
    background: none !important;

    .subscription-wrapper {
      &::before {
        background: #fff;
      }
    }

    @include desktop {
      background: $white;
    }

    .form-control {
      border: 1px solid #ebebeb !important;
    }
  }

  &-wrapper {
    position: relative;
    top: -40px;

    @include desktop {
      top: 0;
      padding: 50px 0;
    }

    &::before {
      position: absolute;
      content: "";
      border-radius: 0% 5% 10% 3%/10% 20% 0% 17%;
      transform: perspective(1000px) rotateY(18deg) skewY(1deg) skewX(2deg);
      width: 100%;
      height: 200px;
      left: 90px;
      background: $bg-gradient-secondary;
      top: -70px;
      @extend .shadow;

      @include desktop-lg {
        left: 65px;
      }

      @include desktop {
        opacity: 0;
      }
    }

    .form-control {
      height: 60px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 45px;

      &:focus {
        background: rgba(255, 255, 255, 0.1);
        outline: 0;
        box-shadow: none;
      }

      &::placeholder {
        color: $gray;
      }

      &.text-white {

        &::placeholder {
          color: $white;
        }
      }
    }
  }
}