// video css start
.video-thumb {
  min-height: 400px;
}

// video css end

// testimonial css start

.bg-shape-triangles {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/background/testimonial-1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
  }
}

// testimonial css end


// pricing css start
.pricing-table {
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    width: 80px;
    height: 70px;
    top: 0;
    right: 40px;
    transform: rotate(45deg) translate(-60px);
    background: rgba(255, 90, 0, 0.1);
    @extend .transition;
  }

  &:before {
    content: '';
    position: absolute;
    width: 95px;
    height: 80px;
    top: 0;
    right: -45px;
    transform: rotate(45deg) translate(-60px);
    background: rgba(255, 90, 0, 0.1);
    @extend .transition;
  }
}

// pricing css end