.text-white p, .text-white>a
{
	color: $white;
}

ul.errors, div.error-inner {
    color: $red;
    font-size: 12px;
    list-style: none;
    padding-left: 15px;
    text-align: left;
    margin-bottom: 0;
}

.ajax-loader {
    width: 40px;
    display: none;
}

.list-styled.style-check li::before {top: 14px;}
.general {
    ul {
        padding-left: 0;
        li {
            position: relative;
            padding-left: 20px;
            list-style-type: none;
            margin-bottom: 1rem;

            &::before {
                position: absolute;
                content: "\f105";
                font-family: "FontAwesome";
                font-size: 14px;
                left: 0;
                transform: translateY(-50%);
                transition: .3s ease;
                content: "\f00c";
                color: #00e0d8;
                top: 14px;
                transform: 0;
            }
            &:hover {
                &::before {
                    color: #007ac7 !important;
                }
            }
        }
    }
}

#mce-success-response,
#mce-error-response,
#mc_embed_signup div.mce_inline_error {
    margin: 7px 0 1em 0;
    padding: 5px 10px;
    background-color: #6B0505;
    font-weight: bold;
    z-index: 1;
    color: #fff;
    position: absolute;
}

#mce-success-response {
    background-color: green;
    font-weight: normal;
}

.client-info {
    position: absolute;
    top: 85%;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}

.card {
    height: 95%;
}

.watermark {
    @include mobile {
        display: none;
    }
}
.watermark-sm {
    @include mobile {
        display: none;
    }
}
@media (max-width: 991px) {
    #mce-success-response,
    #mce-error-response{
        top: 145px;
    }
    #mc_embed_signup div.mce_inline_error {
        top: 152px;
    }
    .subscription-wrapper::before {
        left: 0;
    }
}

@media (min-width: 768px) {
	.g-recaptcha.float-md-right {
		&+ ul.errors {
			clear: both;
			float: right;
		}
	}

    h1.section-title {
        font-size: 40px;
    }
}

@media (max-width: 575px) {
    h1.section-title {
        font-size: 30px;
    }
}