/* feature */
.feature-bg-shape {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.process-bg-shape {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.floating-video {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.play-icon {
  font-size: 18px;
  width: 90px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  background: $white;
  display: inline-block;
  border-radius: 50%;
}

.icon-center {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// work process
.process-steps {
  li {
    &:not(:last-child) {
      margin-bottom: 50px;
    }

    &:nth-child(odd) {
      margin-left: 70px;

      @include desktop {
        margin-left: 0;
      }

      @include tablet {
        margin-left: 70px;
      }
    }
  }
}

.step-number {
  display: block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 35px;
}

// testimonial
.bg-triangles {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    background: url(../images/shape/triangle-double.png);
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    left: 0;
    height: 230px;
    width: 25%;
  }

  &::after {
    position: absolute;
    content: "";
    background: url(../images/shape/triangle-double.png);
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    right: 0;
    height: 230px;
    width: 25%;
    transform: rotate(180deg);
  }
}

.bg-quote {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    width: 70px;
    height: 50px;
    transform: translateX(-50%);
    background: url(../images/quotation/quetesion.png);
    background-repeat: no-repeat;
  }
}

// integration
.icon-sm {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  line-height: 45px;
  text-align: center;
}